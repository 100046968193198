import { Box, Checkbox, Flex, IconButton, Input, Switch, Text, Tooltip } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { ChevronLeftIcon, ChevronRightIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { AssociatedProductsControl } from "./associatedProducts/AssociatedProductsControl"
import { useValidationSchemes } from "../utils/validationSchemеs"
import { ImageType, ProductAPI, ProductVariant, StoreFront, api } from "api"
import { FormButtons, FormField, ImagesControl } from "common/components"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { formStyles } from "common/theme"
import { route } from "features/app/routes"

type FormValues = {
  id: string
  receiptDisplayName: string
  supplierSku: string
  supplierBarcode: string
  status: boolean
  notes: string
  variantOptionValues: { variantOptionValueId: string }[]
  storeFronts: { storeFrontId: string; stock: number; retailPrice: number }[]
  isFavourite: boolean
}

type EditVariantFormProps = {
  product: ProductAPI
  variantToEdit: ProductVariant
  saveNext: (variant: ProductVariant) => void
  goToPrevVariant: (variant: ProductVariant) => void
  variantNumber: number
  allVariantsNumber: number
  isFavouriteDisabled: boolean
}

export const EditVariantForm: FC<EditVariantFormProps> = ({
  product,
  variantToEdit,
  saveNext,
  goToPrevVariant,
  variantNumber,
  allVariantsNumber,
  isFavouriteDisabled,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { editVariantsSchema } = useValidationSchemes()
  const [variant, setVariant] = useState<ProductVariant>(variantToEdit)
  const { data: storeFrontsData } = api.useStoreFronts(TENANCY_ID)
  const { data: variantOptionsData } = api.useVariantOptions(TENANCY_ID, { expand: "variantoptionvalues" })
  const isLastVariant = variantNumber === allVariantsNumber

  const attributes = useMemo(() => {
    if (variantOptionsData) {
      const ids = variant.variantOptionValues.map((value) => value.variantOptionValueId)

      const optionsValues = variantOptionsData?.value?.map((option) => option.variantOptionValues).flat() || []

      return ids.map((id) => optionsValues.find((value) => value.id === id).value) as string[]
    }
  }, [variant, variantOptionsData])

  const {
    formState: { errors },
    getValues,
    register,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(editVariantsSchema),
    mode: "all",
    defaultValues: {
      receiptDisplayName: variant.receiptDisplayName,
      supplierBarcode: variant.supplierBarcode ?? "",
      notes: variant.notes,
      status: variant.status === Statuses.Active,
      isFavourite: variant.isFavourite,
    },
  })

  const formFieldProps = { errors, register }
  const watchStatus = watch("status")

  const isAddFavouriteDisabled = isFavouriteDisabled && !watch("isFavourite")

  const getVariantValues = () => ({
    ...variant,
    ...getValues(),
    status: getValues("status") ? Statuses.Active : Statuses.Inactive,
  })

  const goToNextVariant = () => saveNext(getVariantValues())

  const goToPreviousVariant = () => goToPrevVariant(getVariantValues())

  return (
    <>
      <Flex direction="column" pl="66px" width="100%">
        <CommonFormControl controlWidth="100%" label={t("product.attributes")}>
          <Flex direction="column" gridGap="20px">
            {attributes?.map((item) => (
              <Input key={item} mr={4} value={item} disabled />
            ))}
          </Flex>
        </CommonFormControl>
        <FormField<FormValues>
          label={t("product.receiptDisplayName")}
          name="receiptDisplayName"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="product-receipt-name-field" />
        </FormField>
        <FormField<FormValues>
          label={t("product.supplierBarcode")}
          name="supplierBarcode"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input />
        </FormField>
        <CommonFormControl controlWidth="100%">
          <Flex align="center" justify="flex-start">
            <ImagesControl
              images={variant.images}
              imageType={ImageType.Product}
              label={t("product.productVariantImages")}
              libraryImagesIds={product.images.map((image) => image.imageId)}
              onChange={(images) =>
                setVariant({
                  ...variant,
                  images,
                })
              }
            />
          </Flex>
        </CommonFormControl>
        <CommonFormControl {...formStyles.control} controlWidth="40%" label={t("product.availableStockPerStoreFront")}>
          {variant.storeFronts.map((item) => {
            const store = storeFrontsData?.value?.find((store) => store.id === item.storeFrontId) as StoreFront

            return (
              <Flex key={item.storeFrontId} mb={4} width="100%">
                <Input mr={4} value={store?.name} disabled />
                <Input
                  data-testid="product-available-stock-store-front-field"
                  type="number"
                  value={item.stock}
                  onChange={(event) => {
                    const changes = variant.storeFronts.map((storeItem) => {
                      if (storeItem.storeFrontId === item.storeFrontId) {
                        return {
                          ...storeItem,
                          stock: +event.currentTarget.value,
                        }
                      }

                      return storeItem
                    })
                    setVariant({ ...variant, storeFronts: changes })
                  }}
                />
              </Flex>
            )
          })}
        </CommonFormControl>
        <CommonFormControl {...formStyles.control} controlWidth="40%" label={t("product.retailPricePerStoreFront")}>
          {variant.storeFronts.map((item) => {
            const store = storeFrontsData?.value?.find((store) => store.id === item.storeFrontId) as StoreFront

            return (
              <Flex key={item.storeFrontId} mb={4} width="100%">
                <Input mr={4} value={store?.name} disabled />
                <Input
                  data-testid="product-retail-price-field"
                  type="number"
                  value={item.retailPrice}
                  onChange={(event) => {
                    const changes = variant.storeFronts.map((storeElement) => {
                      if (storeElement.storeFrontId === item.storeFrontId) {
                        return {
                          ...storeElement,
                          retailPrice: +event.currentTarget.value,
                        }
                      }

                      return storeElement
                    })
                    setVariant({ ...variant, storeFronts: changes })
                  }}
                />
              </Flex>
            )
          })}
        </CommonFormControl>
        <CommonFormControl label={t("status_label")}>
          <Flex align="center" width="60%">
            <Switch data-testid="product-status-switch" isChecked={watchStatus} {...register("status")} />
            <Text ml={2}>{watchStatus ? t("product.statusActive") : t("product.statusInactive")}</Text>
          </Flex>
        </CommonFormControl>
        <AssociatedProductsControl
          onChange={(products) =>
            setVariant({
              ...variant,
              associatedToProducts: products.associatedToProducts.map((product) => ({ productId: product.id })),
              crossLinkedToProducts: products.crossLinkedToProducts.map((product) => ({ productId: product.id })),
              associatedToVariants: products.associatedVariants.map((product) => ({
                associatedVariantId: product.id,
              })),
              crossLinkedToVariants: products.crossLinkedVariants.map((product) => ({
                crossLinkedVariantId: product.id,
              })),
            })
          }
        />

        <Tooltip
          isDisabled={!isFavouriteDisabled}
          label={t("product.favouritesMaxTooltip")}
          placement="right"
          variant="info"
          hasArrow
        >
          <Box mb={4} w="fit-content">
            <Checkbox {...register("isFavourite")} isDisabled={isAddFavouriteDisabled} size="lg">
              {t("product.makeItFavourite")}
            </Checkbox>
          </Box>
        </Tooltip>

        <FormField<FormValues>
          label={t("product.notes")}
          name="notes"
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="product-notes-field" placeholder={t("product.placeholder.write", { title: "notes" })} />
        </FormField>
      </Flex>

      <FormButtons
        cancel={() => navigate(route.products.path)}
        cancelWidth="auto"
        done={() => goToNextVariant()}
        titleDone={isLastVariant ? t("confirm") : t("product.saveNnext")}
      />
      <Flex align="center" gap={4} m={4}>
        <Text ml="140px">
          {t("product.variantNumberOfAllVariants", {
            variantNumber,
            allVariants: allVariantsNumber,
          })}
        </Text>
        <IconButton
          aria-label={t("Go to previous variant")}
          disabled={variantNumber === 1}
          icon={<ChevronLeftIcon />}
          variant="appbar"
          onClick={() => goToPreviousVariant()}
        />
        <IconButton
          aria-label={t("Go to next variant")}
          disabled={isLastVariant}
          icon={<ChevronRightIcon />}
          variant="appbar"
          onClick={() => goToNextVariant()}
        />
      </Flex>
    </>
  )
}
