import {
  Box,
  Checkbox,
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { AssociatedProductsControl } from "./associatedProducts/AssociatedProductsControl"
import { useValidationSchemes } from "../utils/validationSchemеs"
import { ImageType, Supplier, api } from "api"
import { ProductAPI } from "api/generated/models/ProductAPI"
import { CommonFormControl, FormCountrySelect, FormField, FormRichSelect, ImagesControl } from "common/components"
import { TENANCY_ID } from "common/constants"
import { InventoryTypes } from "common/inventoryTypes"
import { formStyles } from "common/theme"
import { ProductsContext } from "features/inShop"

type InfoProps = {
  productAPIState: [ProductAPI, Dispatch<SetStateAction<ProductAPI>>]
  onIsValidChange: (isValid: boolean) => void
  getValuesRef: React.MutableRefObject<() => Partial<ProductAPI>>
  isEdit?: boolean
  isAddFavouriteDisabled?: boolean
}

type ProductFormValues = {
  name: string
  description: string
  shortName: string
  image: string
  supplierId: string
  supplierSku: string
  countryOfOriginId: number
  productCategoryId: string
  unitOfMeasureId: string
  inventoryType: string
  retailPrice: string
  isFavourite: boolean
}

const Info: FC<InfoProps> = ({
  productAPIState,
  onIsValidChange,
  isEdit = false,
  getValuesRef,
  isAddFavouriteDisabled,
}) => {
  const { t } = useTranslation()
  const { infoSchema } = useValidationSchemes()
  const [productAPI, setProductAPI] = productAPIState
  const [isAssociatedChanged, setIsAssociatedChanged] = useState(false)
  const [areImagesChanged, setAreImagesChanged] = useState(false)
  const {
    state: {
      globalEntities: { tenancyCurrency },
    },
  } = useContext(ProductsContext)
  const { data: suppliersData } = api.useSuppliers(TENANCY_ID, { filter: `status ne 'Inactive'` })
  const { data: productCategoriesData } = api.useProductCategories(TENANCY_ID, { filter: `status ne 'Inactive'` })
  const { data: unitOfMeasuresData } = api.useUnitOfMeasures(TENANCY_ID)

  const inventoryTypes = [InventoryTypes.Stocked, InventoryTypes.Artisanal, InventoryTypes.OnRequest]

  const productCategoriesSelectOptions = useMemo(
    () => productCategoriesData?.value?.map((category) => ({ label: category.name, value: String(category.id) })) ?? [],
    [productCategoriesData]
  )

  const suppliersSelectOptions = useMemo(
    () => suppliersData?.map((supplier: Supplier) => ({ label: supplier.name, value: String(supplier.id) })) ?? [],
    [suppliersData]
  )

  const {
    formState: { errors, isValid, isDirty },
    watch,
    getValues,
    register,
    setValue,
    reset,
  } = useForm<ProductFormValues>({
    resolver: zodResolver(infoSchema),
    mode: "all",
    defaultValues: {
      name: productAPI.name,
      description: productAPI.description,
      shortName: productAPI.shortName,
      supplierId: productAPI.supplierId,
      supplierSku: productAPI.supplierSku,
      countryOfOriginId: productAPI.countryOfOriginId,
      productCategoryId: productAPI.productCategoryId,
      unitOfMeasureId: productAPI.unitOfMeasureId,
      inventoryType: productAPI.inventoryType,
      retailPrice: productAPI.retailPrice.toFixed(2).toString(),
      isFavourite: productAPI.isFavourite,
    },
  })
  const watchCountryOfOriginId = watch("countryOfOriginId")
  const watchRetailPrice = watch("retailPrice")
  const watchName = watch("name")
  const formFieldProps = { errors, register }
  const watchSupplier = watch("supplierId")
  const watchProductCategoryId = watch("productCategoryId")
  const isInvalid = isEdit ? (!isValid || !isDirty) && !isAssociatedChanged && !areImagesChanged : !isValid
  const isFavouriteDisabled = isAddFavouriteDisabled && !watch("isFavourite")

  useEffect(() => {
    if (!isEdit) {
      setValue("description", watchName)
      setValue(
        "shortName",
        watchName
          .trim()
          .split(" ")
          .map((str) => str.slice(0, 3))
          .join("-")
          .slice(0, 10)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchName])

  useEffect(() => {
    onIsValidChange(!isInvalid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalid])

  useEffect(() => {
    getValuesRef.current = () => ({
      ...getValues(),
      retailPrice: +getValues("retailPrice"),
      countryOfOriginId: +getValues("countryOfOriginId"),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (watchSupplier && watchSupplier !== productAPI.supplierId && !isEdit) {
      const supplier = suppliersData?.find((item) => item.id === watchSupplier) as Supplier
      if (supplier.defaultProductCountryOfOriginId && supplier.defaultProductCategoryId) {
        setProductAPI({ ...productAPI, sellingTaxCategoryId: supplier.buyingTaxCategoryId })
        setValue("countryOfOriginId", +supplier.defaultProductCountryOfOriginId)
        setValue("productCategoryId", supplier.defaultProductCategoryId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSupplier, suppliersData])

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategoriesData, unitOfMeasuresData])

  return (
    <>
      <FormField<ProductFormValues>
        label={t("product.name")}
        name="name"
        isRequired
        {...formFieldProps}
        stackProps={{ ...formStyles.control }}
      >
        <Input data-testid="product-name-field" maxLength={15} placeholder={t("product.placeholder.name")} />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.description")}
        name="description"
        isRequired
        {...formFieldProps}
        stackProps={{ ...formStyles.control }}
      >
        <Textarea data-testid="product-description-field" placeholder={t("product.placeholder.writeDescription")} />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.shortName")}
        name="shortName"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input
          data-testid="product-short-name-field"
          maxLength={10}
          placeholder={t("product.placeholder.writeShortName", { title: "short name" })}
        />
      </FormField>
      <CommonFormControl controlWidth="100%">
        <Flex align="center" justify="flex-start">
          <ImagesControl
            images={productAPI.images}
            imageType={ImageType.Product}
            label={t("product.productImages")}
            onChange={(images) => {
              setAreImagesChanged(true)
              setProductAPI({ ...productAPI, images })
            }}
          />
        </Flex>
      </CommonFormControl>
      <FormField<ProductFormValues>
        errors={errors}
        label={t("product.supplier")}
        name="supplierId"
        stackProps={formStyles.control}
        isRequired
      >
        <FormRichSelect
          dataTestId="supplier-select"
          options={suppliersSelectOptions}
          value={watchSupplier}
          onChange={(val) => setValue("supplierId", val, { shouldDirty: true, shouldValidate: true })}
        />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.supplierSKU")}
        name="supplierSku"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input
          data-testid="product-supplier-sku-field"
          maxLength={15}
          placeholder={t("product.placeholder.common", { title: "short name" })}
        />
      </FormField>
      <FormField<ProductFormValues>
        errors={errors}
        label={t("country")}
        name="countryOfOriginId"
        stackProps={{ ...formStyles.control }}
        isRequired
      >
        <FormCountrySelect
          data-testid="country"
          value={watchCountryOfOriginId.toString()}
          onChange={(val) => setValue("countryOfOriginId", +val, { shouldDirty: true, shouldValidate: true })}
        />
      </FormField>
      <FormField<ProductFormValues>
        errors={errors}
        label={t("product.category")}
        name="productCategoryId"
        stackProps={formStyles.control}
        isRequired
      >
        <FormRichSelect
          dataTestId="category-select"
          options={productCategoriesSelectOptions}
          value={watchProductCategoryId}
          onChange={(val) => setValue("productCategoryId", val, { shouldDirty: true, shouldValidate: true })}
        />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.unitOfMeasure")}
        name="unitOfMeasureId"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Select data-testid="product-unit-select">
          <option value="" disabled>
            {t("product.placeholder.select")}
          </option>
          {unitOfMeasuresData?.value?.map((unitOfMeasure) => (
            <option key={unitOfMeasure.id} value={unitOfMeasure.id}>
              {unitOfMeasure.name}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.inventoryType")}
        name="inventoryType"
        isRequired
        {...formFieldProps}
        stackProps={{ ...formStyles.control }}
      >
        <Select data-testid="product-inventory-select">
          <option value="" disabled>
            {t("product.placeholder.select")}
          </option>
          {inventoryTypes.map((inventoryType) => (
            <option key={inventoryType} value={inventoryType}>
              {inventoryType}
            </option>
          ))}
        </Select>
      </FormField>
      {isEdit && (
        <>
          <CommonFormControl label={t("product.price")}>
            {productAPI.retailPrice > 0 ? (
              <Flex justify="flex-end" width="255px">
                <NumberInput
                  borderRightRadius={0}
                  borderRightWidth={0}
                  data-testid="product-price-field"
                  min={0.01}
                  precision={2}
                  step={0.01}
                  value={watchRetailPrice}
                  width="inherit"
                  allowMouseWheel
                  onChange={(e) => setValue("retailPrice", e, { shouldDirty: +watchRetailPrice !== +e })}
                >
                  <NumberInputField borderRightRadius={0} borderRightWidth={0} px={3} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Box {...boxStyles}>{tenancyCurrency.currency?.symbol}</Box>
              </Flex>
            ) : (
              <Text color="text.01">{t("product.differentPricePerProductVariant")}</Text>
            )}
          </CommonFormControl>
          <AssociatedProductsControl
            productId={productAPI.id}
            onChange={(products, isChanged) => {
              setIsAssociatedChanged(isChanged)
              setProductAPI({
                ...productAPI,
                associatedToProducts: products.associatedToProducts.map((product) => ({
                  associatedProductId: product.id,
                })),
                crossLinkedToProducts: products.crossLinkedToProducts.map((product) => ({
                  crossLinkedProductId: product.id,
                })),
                associatedVariants: products.associatedVariants.map((product) => ({ associatedVariantId: product.id })),
                crossLinkedVariants: products.crossLinkedVariants.map((product) => ({
                  crossLinkedVariantId: product.id,
                })),
              })
            }}
          />
          <Tooltip
            isDisabled={!isFavouriteDisabled}
            label={t("product.favouritesMaxTooltip")}
            placement="right"
            variant="info"
            hasArrow
          >
            <Box mb={4} w="fit-content">
              <Checkbox {...register("isFavourite")} isDisabled={isFavouriteDisabled} size="lg">
                {t("product.makeItFavourite")}
              </Checkbox>
            </Box>
          </Tooltip>
        </>
      )}
    </>
  )
}

const boxStyles = {
  background: "elevation.01",
  borderWidth: 1,
  borderColor: "#cac5c6",
  borderRightRadius: 3,
  fontSize: 14,
  padding: 2,
}

export { Info }
