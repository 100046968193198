import { Button, Flex, useDisclosure } from "@chakra-ui/react"
import { AddIcon, EditIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useEffect, useState } from "react"

import { AssociatedProductsModal } from "./AssociatedProductsModal"
import { getAssociatedAndLinkedProducts } from "./getAssociatedAndLinkedProducts"
import { LONG_FORM_ELEMENT_WIDTH } from "common/theme"

export type SelectedProduct = {
  id: UUID
  name: string
  images?: { thumbnailUrl: string }[]
}

type AAndCProductsOnChangeProps = {
  associatedToProducts: SelectedProduct[]
  crossLinkedToProducts: SelectedProduct[]
  associatedVariants: SelectedProduct[]
  crossLinkedVariants: SelectedProduct[]
}

type Props = {
  onChange: (associatedAndCrossLinkedProducts: AAndCProductsOnChangeProps, isChanged: boolean) => void
  productId?: UUID
  variantId?: UUID
}

export const AssociatedProductsControl = ({ onChange, productId = "", variantId = "" }: Props) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isCrossLinked, setIsCrossLinked] = useState(false)
  const [isStateChanged, setIsStateChanged] = useState(false)

  const [associatedProducts, setAssociatedProducts] = useState<SelectedProduct[]>([])
  const [associatedVariants, setAssociatedVariants] = useState<SelectedProduct[]>([])
  const [crossLinkedProducts, setCrossLinkedProducts] = useState<SelectedProduct[]>([])
  const [crossLinkedVariants, setCrossLinkedVariants] = useState<SelectedProduct[]>([])
  const { associatedProductsData, crossLinkedProductsData } = getAssociatedAndLinkedProducts(productId, variantId)

  useEffect(() => {
    if (associatedProductsData?.value) {
      setAssociatedProducts(getTransformedProducts(associatedProductsData?.value[0].products))
      setAssociatedVariants(getTransformedProducts(associatedProductsData?.value[0].variants))
    }
  }, [associatedProductsData])

  useEffect(() => {
    if (crossLinkedProductsData?.value) {
      setCrossLinkedProducts(getTransformedProducts(crossLinkedProductsData.value[0].products))
      setCrossLinkedVariants(getTransformedProducts(crossLinkedProductsData.value[0].variants))
    }
  }, [crossLinkedProductsData])

  const onDone = (products: SelectedProduct[], variants: SelectedProduct[]) => {
    setIsStateChanged(true)
    if (isCrossLinked) {
      setCrossLinkedProducts(products)
      setCrossLinkedVariants(variants)
    } else {
      setAssociatedProducts(products)
      setAssociatedVariants(variants)
    }
  }

  useEffect(() => {
    onChange(
      {
        associatedToProducts: associatedProducts,
        crossLinkedToProducts: crossLinkedProducts,
        associatedVariants,
        crossLinkedVariants,
      },
      isStateChanged
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedProducts, associatedVariants, crossLinkedProducts, crossLinkedVariants])

  const isEditAssociated = associatedProducts.length || associatedVariants.length
  const isEditCrossLinked = crossLinkedProducts.length || crossLinkedVariants.length

  return (
    <Flex direction="column" gap={5} mb={7}>
      <Button
        data-testid="associate-products-button"
        h="32px"
        variant="primary"
        w={LONG_FORM_ELEMENT_WIDTH}
        onClick={() => {
          setIsCrossLinked(false)
          onOpen()
        }}
      >
        {isEditAssociated ? <EditIcon mr={2} /> : <AddIcon mr={2} />}
        {isEditAssociated
          ? t("product.associatedProducts.editAssociatedProducts")
          : t("product.associatedProducts.associateProducts")}
      </Button>
      <Button
        data-testid="cross-link-products-button"
        h="32px"
        variant="primary"
        w={LONG_FORM_ELEMENT_WIDTH}
        onClick={() => {
          setIsCrossLinked(true)
          onOpen()
        }}
      >
        {isEditCrossLinked ? <EditIcon mr={2} /> : <AddIcon mr={2} />}
        {isEditCrossLinked
          ? t("product.associatedProducts.editCrossLinkedProducts")
          : t("product.associatedProducts.crossLinkProducts")}
      </Button>
      <AssociatedProductsModal
        isCrossLinked={isCrossLinked}
        isOpen={isOpen}
        preselectedProducts={isCrossLinked ? crossLinkedProducts : associatedProducts}
        preselectedVariants={isCrossLinked ? crossLinkedVariants : associatedVariants}
        onClose={onClose}
        onDone={(products, variants) => onDone(products, variants)}
      />
    </Flex>
  )
}

const getTransformedProducts = (products: { id: UUID; name: string; imageUrl: string }[]) =>
  products.map((product) => ({
    ...product,
    images: [{ thumbnailUrl: product.imageUrl }],
  }))
