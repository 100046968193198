import { Box, Checkbox, Flex, Input, Select, Stack, Switch, Text, Tooltip } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { FC, useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { AvailableStockControl } from "./components/AvailableStockControl"
import { AssociatedProductsControl } from "../productsChanges/components/associatedProducts/AssociatedProductsControl"
import { MAX_FAVOURITES_COUNT } from "../productsChanges/maxFavouritesCount"
import {
  ImageType,
  ProductAPI,
  StoreFront,
  VariantAssociatedAndCrossLinkedProducts,
  VariantOptionValue,
  api,
} from "api"
import { CommonFormControl, FormButtons, FormField, ImagesControl, PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { LONG_FORM_ELEMENT_WIDTH, formStyles } from "common/theme"
import { numbersNlettersNDot, numbersNlettersNdash, numbersNlettersNspecialCharacters } from "common/validation"

export type ProductVariantFormValues = {
  productId: string
  receiptDisplayName: string
  sku: string
  supplierSku: string
  supplierBarcode: string
  cost: string
  status: boolean
  notes: string
  storeFronts: { storeFrontId: string; stock: number; retailPrice: number }[]
  isFavourite: boolean
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const ChangeProductVariant: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [productId, setProductId] = useState("")
  const isEdit = params.variantId
  const [associatedAndLinkedProducts, setAssociatedAndLinkedProducts] =
    useState<VariantAssociatedAndCrossLinkedProducts>()
  const [isAssociatedChanged, setIsAssociatedChanged] = useState(false)
  const [images, setImages] = useState<
    {
      imageId: UUID
      isMainImage: boolean
      order: number
    }[]
  >([])
  const [variantOptions, setVariantOptions] = useState<
    { variantOptionId: string; variantOptionValueId: string; name: string }[]
  >([])

  const { data: productsData } = api.useProducts(TENANCY_ID)
  const { data: productStoreFrontsData } = api.useProductStoreFronts(TENANCY_ID, params.productId || productId)
  const { data: variantOptionsData, refetch } = api.useProductVariantOptions(TENANCY_ID, params.productId || productId)
  const { data: storeFrontsData } = api.useStoreFronts(TENANCY_ID)
  const { data: productImagesData } = api.useProductImages(params.productId || productId, TENANCY_ID)
  const { data: productVariantData } = api.useProductVariant(
    TENANCY_ID,
    params.productId || productId,
    params.variantId || ""
  )

  const { data: favouritesCount, isLoading: isFavouritesCountLoading } = api.useFavouriteProductsCount(TENANCY_ID)

  const createVariantMutation = api.useCreateProductVariantMutation(
    { onSuccess: () => navigate(-1) },
    productId,
    TENANCY_ID
  )

  const updateVariantMutation = api.useUpdateProductVariantMutation(
    { onSuccess: () => navigate(-1) },
    productId,
    TENANCY_ID,
    params.variantId || ""
  )

  const schema = z.object({
    productId: z.string().nonempty({ message: t("validation.select", { field: "product" }) }),
    receiptDisplayName: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    supplierSku: z
      .string()
      .max(10)
      .nonempty({ message: t("validation.required", { field: "supplier SKU" }) })
      .regex(numbersNlettersNdash, { message: t("validation.lettersNnumnersNdash") }),
    supplierBarcode: z.string().min(7).nullable().or(z.literal("")),
    cost: z
      .string()
      .max(10)
      .nonempty({ message: t("validation.required", { field: "cost" }) })
      .regex(numbersNlettersNDot, { message: t("validation.lettersNnumnersNdot") }),
    status: z.boolean(),
    notes: z.string().optional(),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    register,
    control,
    reset,
    setValue,
    watch,
  } = useForm<ProductVariantFormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      productId: "",
      receiptDisplayName: "",
      sku: "",
      supplierSku: "",
      supplierBarcode: "",
      cost: "",
      storeFronts: [{ storeFrontId: "", stock: 0, retailPrice: 0 }],
      notes: "",
      status: false,
      isFavourite: false,
    },
  })

  const watchProduct = watch("productId")
  const watchStatus = watch("status")
  const watchIsFavourite = watch("isFavourite")

  const { fields } = useFieldArray({
    control,
    name: "storeFronts",
  })

  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }
  const isFavouriteDisabled = (favouritesCount ?? 0) >= MAX_FAVOURITES_COUNT && !watch("isFavourite")

  useEffect(() => {
    if (productVariantData && params.variantId) {
      reset({
        productId: productVariantData.productId,
        receiptDisplayName: productVariantData.receiptDisplayName,
        sku: productVariantData.sku,
        supplierSku: productVariantData.supplierSku,
        supplierBarcode: productVariantData.supplierBarcode ?? "",
        cost: productVariantData.cost.toString(),
        storeFronts: productVariantData.storeFronts,
        notes: productVariantData.notes,
        status: productVariantData.status === Statuses.Active,
        isFavourite: productVariantData.isFavourite,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariantData, params.variantId])

  useEffect(() => {
    if (!isEdit && variantOptions.length === variantOptionsData?.value?.length) {
      const product = productsData?.value?.find((item) => item.id === productId) as ProductAPI
      const name = variantOptions?.map((item) => item.name.slice(0, 1)).join("-")
      setValue("receiptDisplayName", `${product?.name ? product?.name + "-" : ""}${name}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantOptions])

  useEffect(() => {
    if (params.productId) {
      setValue("productId", params.productId)
      setProductId(params.productId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.productId, productsData])

  useEffect(() => {
    if (watchProduct) {
      setValue("productId", watchProduct)
      setProductId(watchProduct)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProduct])

  useEffect(() => {
    if (productId) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  useEffect(() => {
    if (productStoreFrontsData?.value && !params.variantId) {
      const stores = productStoreFrontsData?.value?.map((item) => ({
        storeFrontId: item.storeFrontId,
        retailPrice: 0,
        stock: 0,
      }))
      setValue("storeFronts", stores)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStoreFrontsData, params.variantId])

  const changeVariant = () => {
    const object = {
      ...getValues(),
      variantOptionValues: variantOptions.map((item) => ({ variantOptionValueId: item.variantOptionValueId })),
      cost: +getValues().cost,
      status: getValues().status ? Statuses.Active : Statuses.Inactive,
      images,
      ...associatedAndLinkedProducts,
    }
    if (isEdit) {
      const { productId, variantOptionValues, sku, images, ...variantAPI } = object

      return updateVariantMutation.mutate(variantAPI)
    }
    createVariantMutation.mutate(object)
  }

  return (
    <>
      <PageHeader
        buttonTitle={t("product.back")}
        isAddIconVisible={false}
        title={isEdit ? t("product.editVariant") : t("product.newVariants")}
        variant="secondary"
        onClick={() => navigate(-1)}
      />
      <Stack pl={20} pr={10} py={4} width="100%">
        {!isEdit && (
          <>
            <FormField<ProductVariantFormValues>
              label={t("product.product")}
              name="productId"
              isRequired
              {...formFieldProps}
              stackProps={formStyles.control}
            >
              <Select disabled={!!params.productId}>
                <option value="" disabled>
                  {t("select")}
                </option>
                {productsData?.value?.map(({ id, name }, index) => (
                  <option key={id || index} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormField>
            <CommonFormControl label={t("product.attributes")} readOnly {...formStyles.control}>
              {variantOptionsData?.value?.map((variantOption, index) => (
                <Flex key={variantOption.id || index} align="center" justify="space-between" mb={6} width="100%">
                  <Text width="30%">{variantOption.name}:</Text>
                  <Select
                    data-testid="attribute-select"
                    defaultValue=""
                    onChange={(event) => {
                      const isExist = variantOptions.find(
                        (optionValue) => optionValue.variantOptionId === variantOption.id
                      )
                      const findOptionValue = variantOption.variantOptionValues.find(
                        (item: VariantOptionValue) => item.id === event.currentTarget.value
                      ) as VariantOptionValue
                      if (isExist) {
                        const changes = variantOptions.map((optionValue) => {
                          if (optionValue.variantOptionId === variantOption.id) {
                            return {
                              variantOptionId: variantOption.id,
                              variantOptionValueId: event.currentTarget.value,
                              name: findOptionValue?.value,
                            }
                          }

                          return optionValue
                        })
                        setVariantOptions(changes)
                      } else {
                        setVariantOptions([
                          ...variantOptions,
                          {
                            variantOptionId: variantOption.id,
                            variantOptionValueId: event.currentTarget.value,
                            name: findOptionValue?.value,
                          },
                        ])
                      }
                    }}
                  >
                    <option value="" disabled>
                      {t("select")}
                    </option>
                    {variantOption.variantOptionValues?.map((optionValue: VariantOptionValue, index: number) => (
                      <option key={optionValue.id || index} value={optionValue.id}>
                        {optionValue.value}
                      </option>
                    ))}
                  </Select>
                </Flex>
              ))}
            </CommonFormControl>
            <CommonFormControl controlWidth="100%">
              <Flex align="center" justify="flex-start">
                <ImagesControl
                  images={images}
                  imageType={ImageType.Product}
                  label={t("product.productVariantImages")}
                  libraryImagesIds={productImagesData?.value?.map((image) => image.imageId)}
                  onChange={(images) => setImages(images)}
                />
              </Flex>
            </CommonFormControl>
          </>
        )}
        <FormField<ProductVariantFormValues>
          label={t("product.receiptDisplayName")}
          name="receiptDisplayName"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="variant-receipt-name-field" />
        </FormField>
        {isEdit && (
          <FormField<ProductVariantFormValues>
            label={t("product.sku")}
            name="sku"
            {...formFieldProps}
            stackProps={formStyles.control}
          >
            <Input data-testid="variant-sku-field" isDisabled />
          </FormField>
        )}
        <FormField<ProductVariantFormValues>
          label={t("product.supplierSKU")}
          name="supplierSku"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="variant-supplier-sku-field" maxLength={10} />
        </FormField>
        <FormField<ProductVariantFormValues>
          label={t("product.supplierBarcode")}
          name="supplierBarcode"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="variant-supplier-barcode-field" {...register("supplierBarcode")} />
        </FormField>
        <FormField<ProductVariantFormValues>
          label={t("product.stockLevel")}
          name="cost"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="variant-stock-level-field" maxLength={10} />
        </FormField>
        <CommonFormControl {...formStyles.control} label={t("product.availableStockPerStoreFront")}>
          <AvailableStockControl
            fields={fields}
            register={register}
            storeFronts={storeFrontsData?.value ?? []}
            onAddNewStore={(id, stock) => {
              setValue(
                "storeFronts",
                [...getValues().storeFronts, { storeFrontId: id, retailPrice: 0, stock: +stock }],
                { shouldDirty: true }
              )
            }}
          />
        </CommonFormControl>
        <CommonFormControl
          {...formStyles.control}
          controlWidth={LONG_FORM_ELEMENT_WIDTH}
          label={t("product.retailPricePerStoreFront")}
        >
          {fields.map((field, index) => {
            const store = storeFrontsData?.value?.find((store) => store.id === field.storeFrontId) as StoreFront

            return (
              <Flex key={field.id} mb={4} width="100%">
                <Input mr={4} value={store?.name || ""} disabled />
                <Input
                  data-testid="variant-retail-price-store-field"
                  maxLength={9}
                  placeholder="1"
                  {...register(`storeFronts.${index}.retailPrice` as const, {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </Flex>
            )
          })}
        </CommonFormControl>
        <AssociatedProductsControl
          productId={params.productId}
          variantId={params.variantId}
          onChange={(products, isChanged) => {
            setIsAssociatedChanged(isChanged)
            setAssociatedAndLinkedProducts({
              associatedToProducts: products.associatedToProducts.map((product) => ({ productId: product.id })),
              crossLinkedToProducts: products.crossLinkedToProducts.map((product) => ({ productId: product.id })),
              associatedToVariants: products.associatedVariants.map((product) => ({ associatedVariantId: product.id })),
              crossLinkedToVariants: products.crossLinkedVariants.map((product) => ({
                crossLinkedVariantId: product.id,
              })),
            })
          }}
        />
        <CommonFormControl label={t("status_label")}>
          <Flex align="center" width="60%">
            <Switch data-testid="variant-status-switch" isChecked={watchStatus} {...register("status")} />
            <Text ml={2}>{watchStatus ? t("product.statusActive") : t("product.statusInactive")}</Text>
          </Flex>
        </CommonFormControl>
        <FormField<ProductVariantFormValues>
          label={t("product.notes")}
          name="notes"
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="variant-notes-field" />
        </FormField>

        <Tooltip
          isDisabled={!isFavouriteDisabled}
          label={t("product.favouritesMaxTooltip")}
          placement="right"
          variant="info"
          hasArrow
        >
          <Box mb={4} w="fit-content">
            <Checkbox
              {...register("isFavourite")}
              isChecked={watchIsFavourite}
              isDisabled={isFavouriteDisabled}
              isReadOnly={isFavouritesCountLoading}
              size="lg"
            >
              {t("product.makeItFavourite")}
            </Checkbox>
          </Box>
        </Tooltip>
        <FormButtons cancel={() => navigate(-1)} disabled={isInvalid && !isAssociatedChanged} done={changeVariant} />
      </Stack>
    </>
  )
}

export { ChangeProductVariant }
