import { AdminRoles } from "api/generated/models/AdminRoles"
import { Status } from "common/components"
import { ValidationErrors } from "common/utils/validation"
import { CommandId } from "features/commands/useCommand/commands"

const common = {
  actions: "{{item}} actions",
  addItem: "Ajouter {{name, lowercase }}",
  addItemSuccess: "{{name}} ajouté",
  addNew: "Ajouter un nouveau {{ item, lowercase }}",
  address: "Addresse",
  addressLine: "Ligne d'Adresse {{ number }}",
  admin: "Admin",
  admin_other: "Admins",
  adminName: "$t(admin) $t(name)",
  adminRole: "$t(admin) $t(role)",
  appName: "Happy Place - BCN Shop",
  back: "Retour",
  backTo: "Retour à {{ location }}",
  cancel: "Annuler",
  catalogue: "Catalog",
  category: "catégorie",
  category_other: "catégories",
  cdm: "Cloud Drive Mapper",
  city: "Ville",
  clear: "Effacer",
  close: "Close",
  command: "Commande",
  companyName: "Nom de la Société",
  conditions: "Conditions",
  conductor: "Conducteur",
  confirm: "Confirmer",
  confirm_header: "Confirmer La Suppression",
  consumer: "Consumer",
  consumer_other: "Consumers",
  contactSupport: "contact support",
  country: "Pays",
  create: "Créer",
  currency: "Devise",
  customer: "Client",
  customer_other: "Clients",
  dashboard: "Tableau de Bord",
  datacentre: "Centre de Données",
  delete: "Supprimer",
  deliveryDate: "Date de Livraison",
  description: "Description",
  details: "Détails",
  discard: "Abandonner",
  discardChanges: "Abandonner Les Modifications",
  discount: "Discount",
  distributor: "Distributor",
  distributor_other: "Distributor",
  domain: "Domaine",
  domain_other: "Domaines",
  done: "Fait",
  edit: "Éditer	",
  editItem: "Éditer {{name}}",
  email: "Email",
  emailAddress: "$t(email) $t(address)",
  entity: "Entité",
  entity_other: "Entités",
  favourites: "Favoris",
  findOutMore: "En savoir plus",
  go: "Aller",
  goTo: "Aller à {{location}}",
  identifier: "Identifiant",
  identifier_other: "Identifiants",
  idx: "Échange d'identité",
  ifProblemPersistsContact: "Si le problème persiste, veuillez contacter",
  image: "Image",
  image_other: "Images",
  inShop: "InShop",
  integration: "Intégration",
  inventory_title: "Inventaire",
  invitation: "État de l'invitation: {{status, lower}}",
  irisMenu: "Menu de l'Iris",
  liftnshift: "Lift&Shift",
  lobby: "Lobby",
  makeDefault: "Faire par défaut",
  managing: "Gérant",
  migration: "Migration",
  migration_other: "Migrations",
  mobile: "Portable",
  name: "Nom",
  no: "No",
  noResults: "Aucun résultat trouvé",
  note: "Note",
  notifications: "Notifications",
  or: "or",
  orders: "Commandes",
  parent: "Parent",
  partner: "Partner",
  partner_other: "Partners",
  phone: "téléphone",
  placeholderNetflix: "par exemple. Netflix",
  post_code: "Code postal",
  price: "Price",
  product_categories: "Catégories de produits",
  productTitle: "Produit",
  profile: "Profil",
  purchase: "Achat",
  region_label: "Région",
  removeItem: "Enlever {{name}}",
  role: "Rôle",
  root: "Root",
  root_other: "Roots",
  sale_other: "Ventes",
  save: "Sauvegarder",
  saveChanges: "Sauvegarder les modification",
  search: "Rechercher",
  searchPlaceholder: "Rechercher...",
  searchProduct: "Search product",
  select: "Sélectionner",
  settings_string: "Paramètres",
  signin: "Connecter",
  signout: "Déconnecter",
  somethingWentWrong: "Quelque chose s'est mal passé",
  sso: "Simple Sign-On",
  status_label: "Statut",
  storeFront: "Vitrine",
  storeFront_other: "Vitrines",
  surpass: "Surp4ss!",
  synchronize: "Synchroniser",
  tenancy: "Propriété",
  tenancy_other: "Propriétés",
  tenancyManagement: "La gestion de propriétés",
  timeZone: "Fuseau Horaire",
  tryAgain: "Essayer à Nouvea",
  type: "Type",
  unableTo: "Incapable de {{action, lowercase}}",
  usersnroles: "Utilisateurs et Rôles",
  variant_option: "Option de variante",
  variant_option_other: "Options de variante",
  website: "Site Internet",
  yes: "Yes",
}

const inShop = {
  addCustomer: "Ajouter un client",
  amount: "Amount",
  amountShouldBeGreatThanTotal: "Le montant doit être supérieur ou égal au total",
  cancelOrder: "annuler la commande",
  card: "Carte",
  cash: "En espèces",
  changePaymentMethod: "Change \n payment \n method",
  changeStorefront: "Changer de vitrine",
  deposit: "Dépôt",
  due: "Solde Dû",
  exact: "Exact",
  iva: "TVA",
  newCustomer: "Nouveau Client",
  newOrder: "Nouvelle Commande",
  noReceipt: "No \n receipt",
  or: "Ou",
  orderSummary: "Récapitulatif de Commande",
  payment: "Paiement",
  paymentAccepted: "Paiement \n accepté",
  paymentDeclined: "Paiement \n refusé",
  pressToStart: "Appuyez Pour Commencer",
  price: "Prix",
  printReceipt: "Print \n receipt",
  processPayment: "Traitez le Paiement",
  quantity: "Quantité",
  remaining: "Solde Restant",
  scanToStart: "Scannez Pour Commencer",
  selectStorefront: "Veuillez sélectionner une vitrine",
  subtotal: "Sous-total",
  total: "Total",
}

const commands: { [key in CommandId]: string } = {
  "auth > signin": common.signin,
  "auth > signout": common.signout,
  "clipboard > copyToken": "Auth: Copy Auth Token to Clipboard",
  "clipboard > copyUser": "Auth: Copy User as JSON to Clipboard ",
  "customer > manage > disable": "Customers: Disable Manage Customer Mode",
  "customer > manage > enable": "Customers: Manage Customer...",
  "customer > manage > selectCustomer": "{{label}}",
  "route > goTo": "Go to Route: {{label}}",
}

const components = {
  deleting_modal: "Voulez-vous supprimer {{name}} {{type}}?",
  downloadAppPage: {
    haveYouDownloadedApp: "Avez-vous téléchargé l'application ?",
    message: "Salut! Il semble que vous essayiez d'accéder à inShop via un navigateur Web.",
  },
  footer: {
    copyright: `© IAM Technology Group Ltd {{year}}. All Rights Reserved.`,
  },
  imagesControl: {
    chooseFiles: "Choisissez les fichiers",
    choosePhoto: "Choisissez une photo",
    dragAndDropAnImageToUpload: "Glissez & Déposez une image à télécharger",
    images: "Images",
    makeTheMainImage: "Make the main image",
    or: "ou",
    photoLibrary: "Galerie de photos",
    uploadPhotos: "Téléchargez des photos",
  },
  input: {
    placeholder: "Veuillez entrer un terme de recherche",
  },
  outOfStockButtonNo: "Non merci",
  outOfStockButtonYes: "Oui s'il vous plaît",
  outOfStockModalContent: "Souhaitez-vous accéder à la page des variantes de produits pour vous réapprovisionner ?",
  outOfStockModalTitle: "L'article est en rupture de stock",
  pagination: {
    itemsPerPage: "objets par page",
    next: "Page suivante",
    ofTotal: "page sur {{count}}",
    ofTotal_plural: "page sur {{count}}",
    page: "Page",
    previous: "Page précédente",
  },
  search: {
    placeholder: "Recherchez {{ item }}",
  },
  textarea: {
    placeholder: "Écrivez un {{item, lowercase}}",
    placeholderFormNote: "Laissez une note...",
  },
  topBar: {
    commandInput: {
      placeholder: `Search for a ${common.command.toLocaleLowerCase()}`,
    },
    tenancyInput: {
      placeholder: `Search for a ${common.tenancy.toLocaleLowerCase()}`,
    },
  },
}

const scannedBarcode = {
  addAsProduct: "Nouveau Produit",
  addAsVariant: "Nouvelle Variante",
  message: "Le code-barres scanné est introuvable dans le système.",
}

const settings = {
  companyInfo: {
    companyInformation: "Informations sur la société",
    companyName: common.companyName,
    industry: "Industrie",
    taxLabel: "Étiquette fiscale",
    taxNumber: "Numéro d'identification fiscale",
  },
  configSearch: {
    checkbox: "Case à Cocher",
    go: common.go,
    placeholder: "Entrez un terme de recherche",
    search: common.search,
  },
  configurations: "Configurations",
  currencies: {
    addNewCurrency: "Ajouter une nouvelle devise",
    currency: common.currency,
    currency_other: "Devises",
    currencyChanges: {
      addANewCurrency: "Ajouter une nouvelle devise",
      currency: common.currency,
      editCurrency: common.editItem,
      exchangeRate: "Taux de change",
      makeDefaultCurrency: "Faire la devise par défaut du système",
      modal: {
        no: "Non, conserver la devise actuelle",
        question: "Vous avez fait de {{name}} votre devise par défaut. Êtes-vous sûr?",
        yes: "Oui, changer la devise par défaut",
      },
      select: common.select,
      tooltipLabel:
        "ISA utilise un échange de marché en direct qui se met à jour toutes les heures. Vous pouvez remplacer le taux de change manuellement.",
    },
    default: "par Défaut",
    defaultCurrency: "Devise par défaut",
    delete: common.delete,
    edit: common.edit,
    makeDefault: common.makeDefault,
    rate: "Taux",
    symbol: "Symbole",
  },
  languages: {
    default: "Par Défaut",
    defaultLanguage: "Langue par défaut",
    edit: common.edit,
    enabled: "Autorisé",
    language: "Langue",
    languageChanges: {
      addNewLanguage: "Ajouter une nouvelle langue",
      editItem: common.editItem,
      enableLanguage: "Activer la langue",
      makeDefaultLanguage: "Définissez la langue par défaut du système",
      makeSystemLanguage: "Faites-en la langue du système",
      modal: {
        default: {
          no: "Non, conservez la langue par défaut actuelle",
          question: "Vous avez fait de {{name}} votre langue par défaut. Cela changera InShop en {{name}}",
          yes: "Oui, changez la langue par défaut",
        },
        system: {
          no: "Non, conservez la langue actuelle du système",
          question: "Vous avez fait de {{name}} la langue de votre système. Cela changera le système en {{name}}",
          yes: "Oui, changez la langue du système",
        },
      },
    },
    makeDefault: common.makeDefault,
    systemLanguage: "Langue du système",
  },
  storeFronts: {
    currency: "Devise",
    defaultProducts: "Ajoutez des produits au magasin par défaut",
    description: "Description du magasin",
    fullSync: "Full sync",
    generateKey: "Generate key",
    hydrateStoreFront: "Hydrate store front",
    language: "Langue",
    maintainStockLevels: "Maintain stock levels",
    physical: "Physique",
    placeholder: {
      description: "Entrez la description",
      name: common.placeholderNetflix,
      webAddress: "http://www.lasociété.com",
    },
    preClearDown: "PreClearDown",
    preClearDownConfirm: "This action will remove all variants from WooCommerce. Are you sure?",
    storeFront: common.storeFront,
    storeFront_other: "Vitrines",
    type: "Type de magasin",
    virtual: "Virtuelle",
    webAddress: "Adresse web",
    websiteType: "Type de site Web",
    websiteValidation: "Doit être sous-domaine + nom + extension",
  },
  troubleshooting: {
    clear: common.clear,
    enableRealtimeLogging: "Activer la journalisation en temps réel",
    send: "Envoyer",
    troubleshooting_title: "Dépannage",
  },
}

const features = {
  admin: {
    configuringTenancies: "Configuration des propriétés",
    noLinkedTenancies: "Vous ne semblez pas avoir de propriétés liées",
    problemGettingDetails: "Un problème est survenu lors de la récupération de vos coordonnées",
    problemLoggingIn: "Un problème est survenu lors de votre connexion",
    settingUpYourProfile: "Configuration de votre profil",
  },
  commands,
  customers: {
    addCustomer: "Ajouter un client",
    customerType: {
      all: "Tous les clients",
      consumer: common.consumer,
      consumer_lower: common.consumer.toLowerCase(),
      consumer_other: common.consumer_other,
      consumer_other_lower: common.consumer_other.toLowerCase(),
      customer: common.customer,
      customer_lower: common.customer.toLowerCase(),
      customer_other: common.customer_other,
      customer_other_lower: common.customer_other.toLowerCase(),
      distributor: common.distributor,
      distributor_lower: common.distributor.toLowerCase(),
      distributor_other: common.distributor_other,
      distributor_other_lower: common.distributor_other.toLowerCase(),
      partner: common.partner,
      partner_lower: common.partner.toLowerCase(),
      partner_other: common.partner_other,
      partner_other_lower: common.partner_other.toLowerCase(),
      root: common.root,
      root_lower: common.root.toLowerCase(),
      root_other: common.root_other,
      root_other_lower: common.root_other.toLowerCase(),
    },
    disableManagedEntity: "Disable manage customer mode",
    noAdmins: "Please <strong>add at least one admin</strong><br/> before creating the new {{customerType}}",
    search: {
      button: "Search {{customerType}}",
      placeholder: "Find {{customerType}} by name",
    },
  },
  landing: {
    signIn: {
      button: "Sign in",
      instruction: "Veuillez vous connecter avant d'accéder à cette page",
    },
  },
}

const products = {
  cdm: {
    description: "Le premier mappeur de lecteur au monde pour Office 365",
    title: common.cdm,
  },
  conductor: {
    description: "Notre plateforme d'automatisation suralimentée",
    title: common.conductor,
  },
  idx: {
    description: "Connectez et synchronisez les identités entre les systèmes",
    title: common.idx,
  },
  liftnshift: {
    description: "Simplifier les migrations vers Office 365",
    title: common.liftnshift,
  },
  sso: {
    description: "Notre plateforme de connexion simple et sécurisée",
    title: common.sso,
  },
  surpass: {
    description: "Réinitialisation du mot de passe basée sur le navigateur pour Active Directory",
    title: common.surpass,
  },
}

const sales = {
  addANewCustomer: "Ajoutez un nouveau client",
  addCustomer: "Ajoutez un nouveau client",
  address: common.address,
  addressLine: common.addressLine,
  applyResellerTax: "Appliquer la surtaxe d'équivalence",
  business: "Entreprise",
  cancel: common.cancel,
  city: "Ville",
  company_name: common.companyName,
  companyName_placeholder: "eg. Netflix LTD",
  companyRegistrationNumber: "Numéro d'enregistrement de la Société",
  country: common.country,
  county: "Comté",
  credit: "Note de crédit",
  customer: "Client",
  customer_other: "Clients",
  customerType: {
    all: "Tous les clients",
    consumer: "Consommateur",
    consumer_other: "Consommateurs",
    customer: common.customer,
    customer_other: common.customer_other,
    distributor: "Distributeur",
    distributor_other: "Distributeurs",
    partner: "Partenaire",
    partner_other: "Partenaires",
    root: "Racine",
    root_other: "Racines",
  },
  customerTypeLabel: "Type de client",
  default_product_category: "Catégorie de produit par défaut",
  default_product_country_of_origin: "Pays d'origine du produit par défaut",
  deliveryAddress: "Delivery address",
  deliveryDate: "Date de livraison",
  disableManagedEntity: "Désactivez le mode Gérer le client",
  editCustomer: "Modifiez le client",
  email: common.email,
  general: "Général",
  individual: "Individuel",
  invoice: "La Facture",
  mobile: "Portable",
  mobileNumber: "Numéro de portable",
  name: common.name,
  next: "Suivant",
  nie: "NIE",
  nifOrCif: "NIF/CIF",
  noAdmins:
    "Veuillez <strong>ajouter au moins un administrateur</strong><br/> avant de créer le nouveau {{customerType}}",
  notes: "Notes",
  orderDate: "Date de commande",
  orderNumber: "Numéro de commande",
  orderStatus: "Order status",
  orderValue: "Valeur de la commande",
  paidAmount: "Paid amount",
  payRemainder: "Pay remainder",
  payment: "Paiement",
  paymentMethod: "Payment method",
  pendingOrders: "Commandes en cours",
  phone: "Téléphone",
  placeholder: {
    companyName: "par exemple Netflix LTD",
    name: common.placeholderNetflix,
  },
  postCode: "Code Postal",
  price_includes_tax: "Le prix comprend la taxe de vente",
  printReceipt: "Imprimer le reçu",
  quantity: "Quantity",
  refundOrExchange: "Refund or Exchange",
  region: "Région",
  remainingAmount: "Remaining amount",
  sales: "Ventes",
  salesOrders: {
    addNewOrder: "Ajouter une nouvelle commande",
    salesOrders: "Les Commandes",
  },
  search: {
    button: "Recherchez {{customerType}}",
    placeholder: "Trouvez {{customerType}} par nom",
    searchEmailOrMobileNumber: "Recherchez un e-mail ou un numéro de téléphone portable",
    searchExistingCustomer: "Recherchez un client existant",
  },
  shipments: "méthodes de livraison",
  state: "État",
  status: common.status_label,
  storeFront: "Vitrine",
  tax_registration_number: "Numéro d'Identification Fiscale",
  totalPaid: "Total paid",
  totalRemaining: "Total remaining",
  townOrCity: "Ville",
  vatRegistrationNumber: "Numéro d'immatriculation à la TVA",
  writeAddress: "Write address",
  writeNotes: "Write notes",
  zipCode: "Code Postal",
}

const purchases = {
  addNewSupplier: "Ajouter un nouveau fournisseur",
  company_name: common.companyName,
  default_product_category: "Catégorie de produit par défaut",
  pending_orders: "Commandes en Cours",
  purchase: "Achats",
  purchase_order: "Ordre d'Achat",
  supplier: "Fournisseur",
  supplier_credit: "crédit_fournisseur",
  suppliers_other: "Fournisseurs",
}

const inventory = {
  addNewCategory: "Ajouter une nouvelle catégorie",
  category_type: "Type de catégorie",
  description: "Description",
  description_placeholder: "Écrire la Description",
  inventory: "Inventaire",
  main_category: "Catégorie Principale",
  name: common.name,
  product: {
    add: common.addItem,
    addMore: "Ajouter plus",
    addNewProduct: `Ajouter un nouveau produit`,
    addStoreFront: "Ajouter une vitrine",
    applyResellerTax: "Appliquer la surtaxe d'équivalence",
    associatedProducts: {
      associateProducts: "Produits Associés",
      clickOnAssociatedProductToDelete: "Click on the associated product you wish to delete",
      crossLinkProducts: "Produits Cross-links",
      deleted: "Deleted",
      doubleClickOnProductOrVariantToBeAdded: "Double click on the product or the product variant to be added",
      editAssociatedProducts: "Modifier les Produits Associés",
      editCrossLinkedProducts: "Modifier les Produits Cross-links",
      searchProductOrVariant: "Search for a product or product variant",
      searchProductToAssociate: "Search or scan a product to associate it",
      searchProductToCrossLink: "Search or scan a product to cross link it",
    },
    attributes: "Les attributs",
    availableStockPerStoreFront: "Stock disponible par vitrine",
    back: common.back,
    carryingCost: "Coût de portage",
    category: "Catégorie",
    confirm: "Confirmer",
    countryOfOrigin: "Pays d'origine",
    delete: common.delete,
    description: common.description,
    differentPricePerProductVariant: "Prix ​​différent par variante de produit",
    edit: common.editItem,
    editProduct: "Modifier le produit {{name}}",
    editVariant: "Modifier la variante",
    enterBarcodeManually: "Entrez le code-barres manuellement",
    enterManually: "Entrer manuellement",
    hsCode: "Code SH",
    image: "Image",
    initialCostOfGoods: "Coût initial des marchandises",
    initialStock: "Stock de Départ",
    initialStockPerVariant: "Stock de Départ par Variante",
    inventory: "Inventaire",
    inventoryType: "Type d'Inventaire",
    makeDefault: common.makeDefault,
    makeItFavourite: "Faites-en un favori",
    name: common.name,
    newVariants: "Nouvelles variantes",
    next: "Suivant",
    notes: "Notes",
    placeholder: {
      common: "l'espace de texte réservé ",
      customHSCode: "Code SH personnalisé",
      name: common.placeholderNetflix,
      select: common.select,
      write: "Écrivez un {{titre}}",
    },
    price: "Prix",
    product: "Produit",
    product_low: "produit",
    product_other: "Produits",
    productCategory: "Catégorie de Produit",
    productImages: "Images du produit",
    productInfo: "Information sur le produit",
    productName: "Nom du produit",
    productVariantImages: "Images de variantes de produit",
    productVariants: "Variantes de Produit",
    receiptDisplayName: "Nom affiché du reçu",
    resellerTaxCategory: "la surtaxe d'équivalence pour Catégorie",
    retailPricePerStoreFront: "Prix de détail par vitrine",
    reviewProductVariants: "Variantes de Produit",
    reviewVariant: "Revoir la variante",
    reviewVariant_plural: "Revoir les variantes",
    rrp: "Prix de vente conseillé",
    rrpIncludesTax: "Prix de vente conseillé, TVA incluse",
    salesTaxCategory: "Taxe de vente par catégorie",
    saveNnext: "Enregistrer et suivant",
    scanBarcode: "Scanner le code-barres",
    select: common.select,
    selectStore: "Sélectionner un magasin",
    shortName: "Nom Court",
    sku: "UGS",
    status: common.status_label,
    statusActive: "Actif",
    statusInactive: "Inactif",
    stockLevel: "Niveau des stocks",
    stockPerStoreFront: "Niveau des stocks par Vitrine",
    storeFront: common.storeFront,
    supplier: "Fournisseur",
    supplierBarcode: "Code-barres du fournisseur",
    supplierSKU: "UGS du Fournisseur",
    tags: "Étiquettes",
    taxInfo: "Informations fiscales",
    totalProductVariants: "Total des variantes de produits",
    totalStock: "Stock total",
    unitCostPerVariant: "Coût unitaire par variante",
    unitOfMeasure: "Unité de mesure",
    unitRRPPerVariant: "Prix de vente conseillé par Variante",
    variant: "Variante",
    variantNumberOfAllVariants: "Variante {{variantNumber}} de {{allVariants}}",
    variantOption: "Option de variante",
    variantOption_other: "Options de variante",
    variants: "Variantes",
    variantsNumber: "{{count}} variantes",
    writeDownTheVariant: "Write down the variant",
  },
  product_category: {
    no_of_subcategories: "Nombre de sous-catégories",
  },
  tax_category: "Catégorie de taxe",
  unitOfMeasure: {
    addNewUnit: "Ajouter une nouvelle unité",
    addNewUnitOfMeasure: "Ajouter une nouvelle unité de mesure",
    shortName: "Nom Court",
    unitOfMeasure: "Unité de mesure",
    unitOfMeasures: "Unité de mesures",
  },
  variant_option: {
    add_new_option: "Ajouter une nouvelle option de variante",
    add_new_option_value: "Ajouter une nouvelle valeur d'option",
    add_new_value: "Add New Value",
    edit_value: "Éditer La Valeur{{name}}",
    image_or_photo: "Valeur Image/Photo",
    no_of_option_values: "Nombre de valeurs d'option",
    option_colour: "Couleur de l'option",
    option_image: "Image de l'option",
    option_name: "Nom de l'option",
    option_value: "Valeur de l'option",
    option_values: "Valeurs d'option de variante",
    placeholder: {
      option_name: "par exemple, le genre, le goût",
      option_values: "Écrivez une valeur et appuyez sur Entrée",
    },
    value: "Valeur",
    value_option: "Valeur de l'option",
    variant_option: "option de variante",
    variant_option_other: common.variant_option_other,
    variantValue: "Valeur de la variante",
  },
}

export const placeholder = {
  email: "nom@domaine.com",
  name: "PRÉNOM NOM",
}

const validation: { [key in ValidationErrors]: string } = {
  email: "S'il vous plaît, mettez une adresses électroniques valide",
  length: "Veuillez saisir {{min}} – {{max}} caractères",
  letters: "Veuillez saisir uniquement des lettres",
  lettersNnumbers: "S'il-vous-plaît n'entrez que des lettres et des nombres",
  lettersNnumbersNspecialCharacters: "Veuillez n'utiliser que des lettres, des chiffres et des caractères spéciaux",
  lettersNnumnersNdash: "Veuillez n'utiliser que des lettres, des chiffres et des tirets",
  lettersNnumnersNdot: "Veuillez n'utiliser que des lettres, des chiffres et des points",
  maxLength: "Veuillez saisir {{length}} caractères ou moins",
  minLength: "Veuillez saisir {{length}} caractères ou plus",
  numbers: "Veuillez utiliser des chiffres",
  phone: "S'il vous plait, entrez un nombre valide",
  required: "Veuillez entrer{{field, lowercase}}",
  select: "Veuillez entrer {{field, lowercase}}",
}

const error = {
  auth: {
    heading: "Hold up!",
    message: "Veuillez vous connecter et réessayer.",
  },
  generic: {
    heading: "Oups griffonnages!",
    message: "S'il vous plaît ne pleurez pas. Appuyez sur F5 et réessayons!",
  },
  pageNotFound: {
    heading: "Les roses sont rouges, les cercles sont ronds",
    link: "Take me home!",
    message: "La page que vous recherchez est introuvable.",
  },
  unauthorised: {
    heading: "Sorry, you don't have access to this page.",
    message: "Please contact your account manager.",
  },
}

const statusIcon: { [key in Status]: string } = {
  Accepted: "Accepté",
  Error: "Erreur",
  Pending: "En attente",
  Rejected: "Refusé",
}

const roles: { [key in AdminRoles]: string } = {
  CDMAdministrator: "Administrateur CDM",
  CustomerAdministrator: "Administrateur Client",
  DistributorAdministrator: "Administrateur Distributeur",
  Finance: "La Finance",
  Helpdesk: "Helpdesk",
  IACAdministrator: "Administrateur IAM Cloud",
  IDxAdministrator: "Administrateur IDx",
  Legal: "Legal",
  MigrationAdministrator: "Administrateur de Migration",
  PartnerAdministrator: "Administrateur partenaire",
  PartnerManager: "Gérant de partenaires",
  TenancyAdministrator: "Administrateur de propriété",
}

const region = {
  eu: "UE",
  uk: "RU",
  us: "US",
}

export const frFr = {
  translation: {
    ...common,
    ...components,
    ...features,
    ...inventory,
    ...inShop,
    ...purchases,
    ...sales,
    ...settings,
    error,
    placeholder,
    products,
    region,
    roles,
    scannedBarcode,
    statusIcon,
    validation,
  },
}
