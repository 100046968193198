import { request as __request } from "../core/request"
import { ProductAPI } from "../models/ProductAPI"
import { StoreFront } from "../models/StoreFront"
import { VariantTypeOption } from "../models/VariantTypeOption"
import { trackPromise } from "react-promise-tracker"

export class ProductsService {
  /**
   * Gets all Products.
   * ## Requires:
   * ### products.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productsGetAllAsync({
    select,
    expand,
    top,
    skip,
    count = false,
    tenancyId,
    filter,
  }: {
    tenancyId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
    filter?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<ProductAPI>
    readonly count: number
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products`,
        query: {
          $select: select,
          $expand: expand,
          $top: top,
          $skip: skip,
          $count: count,
          $filter: `translations/any(t:t/shortname eq '${filter || ""}' or startswith(t/name ,'${filter || ""}'))`,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return { ...result.body, count: result.body["@odata.count"] }
  }

  /**
   * Gets Product Store Fronts.
   * ## Requires:
   * ### productsStoreFronts.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productsGetStoreFrontsAllAsync({
    select,
    expand,
    tenancyId,
    productId,
  }: {
    tenancyId: string
    productId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: { storeFrontId: string; stock: number; storeFront: StoreFront }[]
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${productId}/storefronts`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets all Products.
   * ## Requires:
   * ### productsVariantOptions.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productsGetOptionValuesAllAsync({
    select,
    expand,
    tenancyId,
    productId,
  }: {
    tenancyId: string
    productId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<VariantTypeOption>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/variantoptions?id=${productId}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productsGetFavourites({
    select,
    expand,
    tenancyId,
  }: {
    tenancyId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<VariantTypeOption>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/favourites`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productsGetFavouritesCount({
    select,
    expand,
    tenancyId,
  }: {
    tenancyId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<number> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/favourites/count`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Create SearchingProduct.
   * ## Requires:
   * ### products.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async productsPostAsync({
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    requestBody?: Partial<ProductAPI>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductAPI
  > {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${tenancyId}/isa/products`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets SearchingProduct.
   * ## Requires:
   * ### products.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productsGetAsync({
    tenancyId,
    id,
    select,
    expand,
  }: {
    tenancyId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductAPI
  > {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update SearchingProduct.
   * ## Requires:
   * ### products.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async productsPatchAsync({
    tenancyId,
    id,
    requestBody,
  }: {
    tenancyId: string
    id: string
    requestBody?: Partial<ProductAPI>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductAPI
  > {
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${tenancyId}/isa/products/${id}`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the SearchingProduct.
   * ## Requires:
   * ### products.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async productsDeleteAsync({ tenancyId, id }: { tenancyId: string; id: string }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductAPI
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${tenancyId}/isa/products/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productGetAssociatedProductsAsync({
    tenancyId,
    productId,
  }: {
    tenancyId: string
    productId: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: {
      products: { id: UUID; name: string; imageUrl: string }[]
      variants: { id: UUID; name: string; imageUrl: string }[]
    }[]
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/associated?productId=${productId}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productGetCrossLinkedProductsAsync({
    tenancyId,
    productId,
  }: {
    tenancyId: string
    productId: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: {
      products: { id: UUID; name: string; imageUrl: string }[]
      variants: { id: UUID; name: string; imageUrl: string }[]
    }[]
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/crosslinked?productId=${productId}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
