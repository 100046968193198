import { Flex, Input, Select, Textarea } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { Category, ImageType, api } from "api"
import { FormButtons, FormField, ImagesControlSingle } from "common/components"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { COUNTRY_ID, TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { formStyles } from "common/theme"
type CategoryFormProps = {
  isEdit?: boolean
  category: Category
  onDone: (category: Partial<Category>) => void
}

enum CategoryType {
  Main = "Main Category",
  Sub = "Sub Category",
}

type ProductCategoryFormValues = {
  name: string
  description: string
  categoryType: string
  parentCategoryId: string | null
  taxCategoryId: string
  status: string
  imageId: string | null
}

const CategoryForm: FC<CategoryFormProps> = ({ category, onDone, isEdit }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const typeCategories = [CategoryType.Main, CategoryType.Sub]
  const [mainCategories, setMainCategories] = useState<Category[]>([])
  const statuses = [Statuses.Active, Statuses.Inactive]

  const { data: taxCategories } = api.useTaxCategories(COUNTRY_ID)
  const { data: productCategories } = api.useProductCategories(TENANCY_ID)

  const schema = z.object({
    name: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(/^[\w\\[\]` !@#$%^&*()={}:;<>+'-]+$/, { message: "Please use only letters, numbers and special symbols" }),
    description: z.string().max(200).optional(),
    categoryType: z.string().nonempty({ message: t("validation.select", { field: "category type" }) }),
    parentCategoryId: z.string().nullable().optional(),
    taxCategoryId: z.string().nonempty({ message: t("validation.select", { field: "tax category" }) }),
    status: z.string().nonempty({ message: t("validation.select", { field: "status" }) }),
    imageId: z.string().nullable().optional(),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    watch,
    reset,
    register,
  } = useForm<ProductCategoryFormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      categoryType: "",
      parentCategoryId: "",
      taxCategoryId: "",
      status: "",
      imageId: "",
    },
  })
  const watchMainCategory = watch("parentCategoryId")
  const watchCategoryType = watch("categoryType")
  const watchImageId = watch("imageId")
  const isInvalid =
    !isDirty || !isValid || (getValues().categoryType === CategoryType.Sub && !getValues().parentCategoryId)
  const formFieldProps = { errors, register }

  useEffect(() => {
    reset({
      name: category.name,
      description: category.description,
      categoryType: category.parentCategoryId ? CategoryType.Sub : CategoryType.Main,
      parentCategoryId: category.parentCategoryId,
      taxCategoryId: category.taxCategoryId,
      status: category.status,
      imageId: category.imageId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, category])

  useEffect(() => {
    if (productCategories?.value) {
      const categories = productCategories?.value?.filter((item) => !item.parentCategoryId)
      setMainCategories(categories)
    }
  }, [productCategories])

  useEffect(() => {
    if (getValues().parentCategoryId) {
      const category = productCategories?.value?.find((item) => item.id === getValues().parentCategoryId) as Category
      setValue("taxCategoryId", category.taxCategoryId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMainCategory])

  return (
    <Flex direction="column" pl={20} pr={4} py={4} width="100%">
      <FormField<ProductCategoryFormValues>
        label={t("name")}
        name="name"
        isRequired
        {...formFieldProps}
        stackProps={{ ...formStyles.control, width: "40%" }}
      >
        <Input data-testid="category-name-field" maxLength={15} />
      </FormField>
      <FormField<ProductCategoryFormValues>
        label={t("description")}
        name="description"
        {...formFieldProps}
        stackProps={{ ...formStyles.control, width: "40%" }}
      >
        <Textarea data-testid="description-category-field" placeholder={t("product.placeholder.writeDescription")} />
      </FormField>

      <CommonFormControl controlWidth="100%">
        <Flex align="center" justify="flex-start">
          <ImagesControlSingle
            imageId={watchImageId}
            imageType={ImageType.ProductCategory}
            onChange={(imageId) => {
              setValue("imageId", imageId ?? null, { shouldDirty: true })
            }}
          />
        </Flex>
      </CommonFormControl>

      <FormField<ProductCategoryFormValues>
        label={t("category_type")}
        name="categoryType"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Select>
          <option data-testid="select-type-field" value="" disabled>
            {t("select")}
          </option>
          {typeCategories.map((item) => (
            <option key={item} value={item}>
              {item === CategoryType.Main ? t("main_category") : t("subcategory")}
            </option>
          ))}
        </Select>
      </FormField>
      {watchCategoryType === CategoryType.Sub && (
        <FormField<ProductCategoryFormValues>
          label={t("main_category")}
          name="parentCategoryId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="main-category-field">
            <option value="" disabled>
              {t("select")}
            </option>
            {mainCategories.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>
        </FormField>
      )}
      <FormField<ProductCategoryFormValues>
        label={t("tax_category")}
        name="taxCategoryId"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Select data-testid="select-tag-category-field">
          <option value="" disabled>
            {t("select")}
          </option>
          {taxCategories?.value?.map((taxCategory) => (
            <option key={taxCategory.id} value={taxCategory.id}>
              {taxCategory.name}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField<ProductCategoryFormValues>
        label={t("status_label")}
        name="status"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Select data-testid="select-category-status-field">
          <option value="" disabled>
            {t("select")}
          </option>
          {statuses.map((status) => (
            <option key={status} value={status}>
              {t(`statuses.${status}`)}
            </option>
          ))}
        </Select>
      </FormField>
      <FormButtons cancel={() => navigate(-1)} disabled={isInvalid} done={() => onDone(getValues())} />
    </Flex>
  )
}

export { CategoryForm }

export type { CategoryFormProps, CategoryType }
