import { Flex } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Info } from "./components/Info"
import { MAX_FAVOURITES_COUNT } from "./maxFavouritesCount"
import { ProductAPI, api, mockData } from "api"
import { FormButtons, PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"

export const EditProduct: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [productAPI, setProductAPI] = useState<ProductAPI>(mockData.initProductAPI)
  const { data: productData } = api.useProduct(TENANCY_ID, params.id || "", { expand: "variants" })
  const { data: imagesData } = api.useProductImages(productAPI.id, TENANCY_ID)
  const { data: favouritesCount } = api.useFavouriteProductsCount(TENANCY_ID)

  const notFavouriteVariantsNumber = productData?.variants.filter((variant) => !variant.isFavourite).length ?? 0
  const isAddFavouriteDisabled = (favouritesCount ?? 0) + notFavouriteVariantsNumber > MAX_FAVOURITES_COUNT

  useEffect(() => {
    if (imagesData?.value?.length) {
      setProductAPI({
        ...productAPI,
        images: imagesData?.value?.map(({ imageId, isMainImage, order }) => ({ imageId, isMainImage, order })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesData?.value])

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (productData) setProductAPI(productData)
  }, [productData])

  const updateProductMutation = api.useUpdateProductMutation(
    {
      onSuccess: () => navigate(-1),
    },
    TENANCY_ID,
    params.id || ""
  )

  const infoRef = useRef<() => Partial<ProductAPI>>(() => productAPI)

  const editProduct = (product: Partial<ProductAPI>) => {
    const productItem = {
      supplierId: product.supplierId,
      supplierSku: product.supplierSku,
      supplierBarcode: product.supplierBarcode,
      countryOfOriginId: product.countryOfOriginId,
      productCategoryId: product.productCategoryId,
      unitOfMeasureId: product.unitOfMeasureId,
      inventoryType: product.inventoryType,
      retailPrice: product.retailPrice,
      associatedToProducts: productAPI.associatedToProducts,
      crossLinkedToProducts: productAPI.crossLinkedToProducts,
      associatedVariants: productAPI.associatedVariants,
      crossLinkedVariants: productAPI.crossLinkedVariants,
      images: productAPI.images,
      isFavourite: product.isFavourite,
      translations: [
        {
          languageId: "57b0e5c7-a567-499c-a07b-cbf712b707a2",
          name: product.name,
          description: product.description,
          shortName: product.shortName,
        },
      ],
    }
    updateProductMutation.mutate(productItem)
  }

  return (
    <>
      <PageHeader subtitle={productAPI.name} title={t("product.editProduct", { name: "" })} isArrow isGoBack />
      <Flex direction="column" pl={20} pr={10} py={4}>
        {productAPI.id && (
          <Info
            getValuesRef={infoRef}
            isAddFavouriteDisabled={isAddFavouriteDisabled}
            productAPIState={[productAPI, setProductAPI]}
            isEdit
            onIsValidChange={(isValid) => setIsFormValid(isValid)}
          />
        )}
        <FormButtons
          cancel={() => navigate(-1)}
          disabled={!isFormValid}
          done={() => editProduct(infoRef.current())}
          titleDone={t("done")}
        />
      </Flex>
    </>
  )
}
